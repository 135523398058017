.home-title {
    font-size: 8em;
    color: $white;
    letter-spacing: 14px;
    font-weight: 800;
}
.home-title:before {
    left:0;
    animation: scale 1s linear infinite;
    -webkit-animation: scale 1s linear infinite;
    left: 57px;
    animation: scale 1s linear infinite .2s;
    -webkit-animation: scale 1s linear infinite .2s;
}
.bounced{
    display: flex;
    justify-content: center;
.letter {
    position: relative;
    animation: bouncez 2s ease infinite 4s;
    -webkit-animation: bouncez 2s ease infinite 4s;
}
}
.mini-bounced{
    text-transform: none;
    display: flex;
    letter-spacing: 4px;
.letter {
    position: relative;
    animation: mini-bouncez 2s ease infinite;
    -webkit-animation: mini-bouncez 2s ease infinite;
}
}

.avenir{
    font-family: "Avenir" !important;
}

@keyframes bouncez {
    0%   { transform: scale(1,1) translateY(0); }
    5%  { transform: scale(1.1,.9) translateY(0); }
    15%  { transform: scale(.9,1.1)   translateY(-55px);}
    25%  { transform: scale(1.05,.95) translateY(0); }
    29%  { transform: scale(1,1) translateY(-7px); }
    32%  { transform: scale(1,1) translateY(0);}
    55%, 100% { transform: scale(1,1) translateY(0);}
}
@-webkit-keyframes bouncez {
    0%   { transform: scale(1,1) translateY(0); }
    5%  { transform: scale(1.1,.9) translateY(0); }
    15%  { transform: scale(.9,1.1)   translateY(-55px);}
    25%  { transform: scale(1.05,.95) translateY(0); }
    29%  { transform: scale(1,1) translateY(-7px);}
    32%  { transform: scale(1,1) translateY(0);}
    55%, 100% { transform: scale(1,1) translateY(0);} 
}

@keyframes mini-bouncez {
    0%   { transform: scale(1,1) translateY(0); }
    5%  { transform: scale(1.1,.9) translateY(0); }
    15%  { transform: scale(.9,1.1)   translateY(-25px);}
    25%  { transform: scale(1.05,.95) translateY(0); }
    29%  { transform: scale(1,1) translateY(-3px); }
    32%  { transform: scale(1,1) translateY(0);}
    50% { transform: scale(1,1) translateY(0);}
    55%, 100% { transform: scale(1,1) translateY(0);}
}
@-webkit-keyframes mini-bouncez {
    0%  { transform: scale(1,1) translateY(0); }
    5%  { transform: scale(1.1,.9) translateY(0); }
    15%  { transform: scale(.9,1.1)   translateY(-25px);}
    25%  { transform: scale(1.05,.95) translateY(0); }
    29%  { transform: scale(1,1) translateY(-3px);}
    32%  { transform: scale(1,1) translateY(0);}
    50% { transform: scale(1,1) translateY(0);} 
    55%, 100% { transform: scale(1,1) translateY(0);}
}

@keyframes scale {
    0% {transform: scaleX(1);}
    25% {transform: scaleX(0.4);}
    50% {transform: scaleX(1);}
    75% {transform: scaleX(0.9);}
    100% {transform: scaleX(1);}
}

@-webkit-keyframes scale {
    0% {transform: scaleX(1);}
    25% {transform: scaleX(0.4);}
    50% {transform: scaleX(1);}
    75% {transform: scaleX(0.9);}
    100% {transform: scaleX(1);}
}

@keyframes bounced {
    0% {
        opacity: 0;
        margin-top: 0px ;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        margin-top: -100px ;
    
    }
}