// Global settings

$enable-caret:              true !default;
$enable-rounded:            true !default;
$enable-shadows:            true !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;


// Color system

$white:    #ffffff !default;
$gray-100: #f6f9fc !default;
$gray-200: #e9ecef !default;
$gray-300: #e3e3e3 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;   // Line footer color
$gray-700: #525f7f !default;   // Line p color
$gray-800: #32325d !default;   // Line heading color
$gray-900: #212529 !default;
$link-disabled:              #666666 !default;
$transparent-bg:             transparent !default;
$light-bg:                   #f5f6fa !default;
$dark-background:            #555555 !default;


$grays: () !default;
$grays: map-merge((
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
), $grays);

$blue:    #5e72e4 !default;
$indigo:  #5603ad !default;
$purple:  #8965e0 !default;
$pink:    #f3a4b5 !default;
$red:     #f5365c !default;
$orange:  #fb6340 !default;
$yellow:  #ffd600 !default;
$green:   #2dce89 !default;
$teal:    #11cdef !default;
$cyan:    #2bffc6 !default;


$line-height-lg:              1.625rem !default;
$line-height-sm:              1.5 !default;
$line-height:                 1.35em         !default;


$opacity-gray-3:             rgba(222,222,222, .3) !default;
$opacity-gray-5:             rgba(222,222,222, .5) !default;
$opacity-gray-8:             rgba(222,222,222, .8) !default;


$opacity-5:                  rgba(255,255,255, .5) !default;
$opacity-6:                  rgba(255,255,255, .6) !default;
$opacity-8:                  rgba(255,255,255, .8) !default;



$opacity-1:                  rgba(255,255,255, .1) !default;
$opacity-2:                  rgba(255,255,255, .2) !default;


// end beniamin

$colors: () !default;
$colors: map-merge((
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "light":      $gray-400,
  "lighter":    $gray-200,
  "gray-dark":  $gray-800
), $colors);

$default-opacity:      rgba(182, 182, 182, .6) !default;
$orange-color:               #f96332 !default;

$default:       #162117 !default;
$primary:       #357f84 !default;
$secondary:     #e3b025 !default;
$ternary:       #cde4dc !default;
$success:       #00f2c3 !default;
$info:          #1d8cf8 !default;
$warning:       #ff8d72 !default;
$danger:        #fd5d93 !default;
$black:         #162117 !default;


// gradient
$default-states:       #264835 !default;
$primary-states:       #357f84 !default;
$success-states:       #cde4dc !default;
$info-states:          #1d8cf8 !default;
$warning-states:       #ff6491 !default;
$danger-states:        #ec250d !default;
$black-states:         #162117 !default;

$background-black:           #171941 !default;
$background-states-black:    #1e1e24 !default;

// opacity
$default-opacity:      rgba(93, 167, 182, .6) !default;
$primary-opacity:      rgba(53, 127, 132, .3) !default;
$success-opacity:      rgba(24, 206, 15, .3) !default;
$info-opacity:         rgba(44, 168, 255, .3) !default;
$warning-opacity:      rgba(255, 178, 54, .3) !default;
$danger-opacity:       rgba(255, 54, 54, .3) !default;

$light-gray:                 #E3E3E3 !default;
$medium-gray:                #DDDDDD !default;
$dark-gray:                  #9A9A9A !default;
$card-stats-gray:            #808080 !default;
$active-gray:                #777777 !default;
$nav-gray:                   #444444 !default;
$search-gray:                #b7b7b7 !default;
$calendar-gray:              #9499a7 !default;
$active-blue:                #cde4dc !default;